import React from 'react';
import './style.css';

import logoKSmin from '../../../images/logoKS_min.png';
import {Link} from "react-router-dom";
import { analytics } from '../../../Firebase.js';
import { logEvent } from "firebase/analytics";

class PrimeraCopa extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
        document.title ="Ki Systems | Constancia";
        document.getElementsByTagName("html")[0].classList = "";
        document.getElementsByTagName("html")[0].classList.add("stylePrimeraCopa");
	}

	render() {
		return(
			<React.Fragment>
				<nav>
					<div id='nav-bg'>
						<div id='a'></div>
						<div id='b'></div>
						<div id='c'></div>
						<div id='d'></div>
						<Link id="logo" to='/'><img src={logoKSmin} /></Link>
					</div>
				</nav>

                <main>
					<div className='info'>
						<p className='subtitle-page'>Registro de constancia</p>
						<h1>Constancia de participación</h1>

						<div className='detail'>
							<i class="fa-solid fa-circle-check"></i>
							<h2>Constancia válida</h2>
							<p>Constancia emitida por <b>Ki Systems®</b> para el evento con folio:</p>
							<h3>PTCKS-JRP-80-001</h3>
							<p className='st'>Datos del evento:</p>

							<div>
								<p>
									Torneo nacional abierto de Taekwondo<br/>
									Copa Ki Systems<br/>
									SPORT PLAZA Puebla, Pue.<br/>
									10 de febrero del 2024<br/>
								</p>
							</div>
						</div>

						<Link id='action-btn' to='/' onClick={() => logEvent(analytics, "Click en próximos eventos")}>Próximos eventos</Link>
					</div>

					<footer>
						<div>
							<Link to='/'><i className="fa-solid fa-house"></i></Link>
						</div>
						<p>KiSystems Copyright © 2024 - All rights reserved || Designed By: KiSystems</p>
					</footer>
                </main>
			</React.Fragment>
		);
	}

}

export default PrimeraCopa;