const popupTitle = (e, text, time, bgcolor, color) => {
    if(e != null) { e.target.disabled = true; }
    let popup = document.createElement("div");
    popup.classList.add("popup-title");
    popup.classList.add("animate__animated");
    popup.classList.add("animate__fadeInUp");
    popup.setAttribute("id", "popup-title-"+((e != null)?e.target.id:""));
    if(color) { 
        popup.style.backgroundColor = bgcolor; 
        popup.style.color = color;
    }
    popup.innerHTML = text;
    document.getElementById("root").insertAdjacentHTML("beforeend", popup.outerHTML);
    setTimeout(() => {
        if(document.getElementById("popup-title-"+((e != null)?e.target.id:""))) {
            document.getElementById("popup-title-"+((e != null)?e.target.id:"")).classList.remove("animate__fadeInUp");
            document.getElementById("popup-title-"+((e != null)?e.target.id:"")).classList.add("animate__fadeOut");
        }
        setTimeout(() => {
            if(document.getElementById("popup-title-"+((e != null)?e.target.id:""))) {
                document.getElementById("popup-title-"+((e != null)?e.target.id:"")).remove();
            }
            if(e != null) { e.target.disabled = false; }
        }, 800);
    }, (time)?time:2000);
};

export default popupTitle;