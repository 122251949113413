import React from 'react';
import { useEffect, useState } from 'react';
import { Link, Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import './style.css';
import logoKSmin from '../../images/logoKS_min.png';
import Compras from './Compras.js';
import Soporte from './Soporte.js';
import { auth } from '../../Firebase.js';
import { signOut } from "firebase/auth";
import { db } from '../../Firebase.js';
import popupTitle from '../../Utils.js';
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";

const Clientes = () => {
    const [nombre, setNombre] = useState(null);
    const [correo, setCorreo] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title ="Ki Systems | Clientes";
        document.getElementsByTagName("html")[0].classList = "";
        document.getElementsByTagName("html")[0].classList.add("styleClientes");
        auth.onAuthStateChanged(async user => {
            if (user) {
                const results = await getDocs(query(collection(db, "usuarios"), where("id_usuario", "==", user.uid)));
                switch (results.docs[0].data().tipo_usuario) {
                    case "administrador": navigate("/administracion", { replace: true }); break;
                    case "cliente": {
                        let usuario = (await getDoc(doc(db, "clientes", user.uid))).data();
                        setNombre(usuario.nombre);
                        setCorreo(usuario.correo);
                    } break;
                }
            } else { navigate("/clientes", { replace: true }); }
        });
    }, []);

    return (
        <React.Fragment>
            <div id="sidebar" className='animate__animated animate__slideInLeft'>
                <div className="top">
                    <img src={logoKSmin}/>
                    <i 
                    className="fa-solid fa-bars"
                    onClick={(e => e.target.parentElement.parentElement.classList.toggle("open"))}
                    ></i>
                </div>
                <p className="cliente">CLIENTE</p>
                <p className="nombre">{(nombre!=null)?nombre:<i>&nbsp;</i>}</p>
                <p className="correo">{(correo!=null)?correo:<i>&nbsp;</i>}</p>
                <Link 
                    className={(() => {
                        return (location.pathname.includes('/compras'))?"menuOption selected":"menuOption";
                    })()}
                    to="/clientes/compras"
                >
                    <i className="fa-solid fa-bag-shopping"></i> Mis compras
                </Link>
                <Link
                    className={(() => {
                        return (location.pathname.includes('/soporte'))?"menuOption selected":"menuOption";
                    })()}
                    to="/clientes/soporte"
                >
                    <i className="fa-solid fa-headset"></i> Soporte técnico
                </Link>
                <button onClick={e => {
                    signOut(auth).then(() => {
                        navigate("/clientes", { replace: true });
                    }).catch(() => {
                        popupTitle(e, "Error al cerrar sesión &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 2000, "#ff8c84", "#65100A");
                    });
                }}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i> Cerrar sesión
                </button>
            </div>

            <div id="main">
                <Routes>
                    <Route path="" element={<Compras />} />
                    <Route path="/compras" element={<Compras />} />
                    <Route path="/soporte" element={<Soporte />} />
                </Routes>
            </div>
        </React.Fragment>
    );
};

export default Clientes;