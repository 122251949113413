import React from 'react';
import {useEffect, useState} from 'react';
import './style.css';
import Swal from 'sweetalert2';
import smtkdPromo from '../../images/clientes/smtkdPromo.png';
import withReactContent from 'sweetalert2-react-content'
import popupTitle from '../../Utils.js';
import { auth } from '../../Firebase.js';
import { db } from '../../Firebase.js';
import { collection, query, where, getDocs, doc, Timestamp, getDoc } from "firebase/firestore";
import moment from 'moment';

const Compras = () => {
    const [dataCompras, setDataCompras] = useState([]);

    useEffect(() => {
        document.getElementById("main").scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        auth.onAuthStateChanged(user => {
            if (user) {
                getDocs(query(collection(db, "compras"), where("cliente", "==", doc(db, "clientes", user.uid)))).then(async docs => {
                    let compras = await Promise.all(
                        Array.from(docs.docs).map(async doc => {
                            let data = doc.data();
                            return {
                                producto: (await getDoc(data.producto)).data(),
                                fecha: moment(data.fecha.toDate()).format('DD-MM-YYYY'),
                                id: doc.id
                            };
                        })
                    );
                    setDataCompras(compras);
                });
            }
        });
    }, []);

    const verCopias = (idCompra, nombreProducto) => {
        getDocs(query(collection(db, "claves"), where("compra", "==", doc(db, "compras", idCompra)))).then(async docs => {
            let claves = await Promise.all(
                Array.from(docs.docs).map(async doc => {
                    let data = doc.data();
                    return {
                        clave: data.clave,
                        estatus: (await getDoc(data.estatus)).data().nombre,
                        datosHardware: (data.datosHardware)?data.datosHardware:"Clave aún no utilizada",
                        fecha: (data.fecha)?moment(data.fecha.toDate()).format('DD-MM-YYYY'):"Clave aún no utilizada",
                    };
                })
            );
            
            //----------------------------------------------------------------------------------------------------------------------------
            withReactContent(Swal).fire({
                title:  "Activación por copia",
                html:   <React.Fragment>
                        <p>
                             A continuación, se muestran las claves de activación disponibles asociadas a su compra,
                             cada clave se usa para activar una copia del producto en dispositivos únicos, es decir,
                             cada clave representa una copia diferente del producto adquirido.
                        </p>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='hide-on-mobile'>
                                            Clave
                                        </th>
                                        <th>
                                            Estatus
                                        </th>
                                        <th className='hide-on-mobile'>
                                            Dispositivo
                                        </th>
                                        <th className='hide-on-mobile'>
                                            Fecha de activación
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody id='tablaCopias'>
                                    {
                                        claves.map(clave => {
                                            return(
                                                <tr key={clave.clave}>
                                                    <td className='hide-on-mobile'>
                                                        ● ● ● ● ● ●
                                                    </td>
                                                    <td>
                                                        {clave.estatus}
                                                    </td>
                                                    <td className='hide-on-mobile'>
                                                        {clave.datosHardware}
                                                    </td>
                                                    <td className='hide-on-mobile'>
                                                        {clave.fecha}
                                                    </td>
                                                    <td>
                                                        <button onClick={e => {
                                                            Swal.close();
                                                            setTimeout(() => {
                                                                verClave(clave.clave, nombreProducto);
                                                            }, 270);
                                                        }}>
                                                            VER CLAVE
                                                            <i className='fa-solid fa-envelope-open-text'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        </React.Fragment>,
                footer: <p>**Se puede hacer uso de una clave previamente utilizada siempre y cuando sea sobre el mismo dispositivo donde se utilizó inicialmente</p>,
                width:  "1400px",
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
                customClass: {
                    cancelButton: 'cancelButton',
                    confirmButton: 'confirmButton',
                    closeButton: `pressed closeButton`,
                    actions: 'actions',
                    content: 'content',
                    popup: 'show-copies',
                    htmlContainer: 'htmlContainer',
                    title: 'title',
                    footer: 'footer',
                    container: 'show-copias-container'
                },
                showClass: {
                    popup: `
                      animate__animated
                      animate__zoomIn
                      animate__faster
                    `
                },
                hideClass: {
                    popup: `
                      animate__animated
                      animate__fadeOutUp
                      animate__faster
                    `
                },
                didOpen: ()=> {
                },
                willClose: () => {
                }
            });
            //----------------------------------------------------------------------------------------------------------------------------
        });
    };

    const verClave = (clave, nombreProducto) => {
        withReactContent(Swal).fire({
            title:  <div className='titleContainer'>
                        <h1>
                            Clave de activación
                        </h1>
                        <div className='linkVideo'>
                            <p>
                                ¿Cómo realizo la activación<br/>de mi producto?
                            </p>
                            <a href='https://youtu.be/nqSnu-RE2Xg?si=W093wWQ2OIn5WjHq' target='_blank'>
                                <i className='fa-solid fa-film'></i>Ver vídeo
                            </a>
                        </div>
                    </div>,
            html:   <React.Fragment>
                    <div id='agradecimientos'>
                        <p style={{'fontWeight': '600'}}>Gracias por adquirir</p>
                        <p>{nombreProducto}</p>
                        <img src={smtkdPromo}/>
                    </div>
                    <div id='clave'>
                        <p>Copie la clave e ingrésela dentro del software en el apartado de activación:</p>
                        <div className='key-container'>
                            <div>
                                <p>{clave}</p>
                            </div>
                            <button 
                                title='Copiar'
                                onClick={async (e) => {
                                    await navigator.clipboard.writeText(clave);
                                    popupTitle(e, "Clave copiada en portapapeles &nbsp;<i class='fa-regular fa-copy'></i>", 3000);
                                }} id='copiarClave'
                            ><i className='fa-regular fa-copy'></i></button>
                        </div>
                        <p>
                            La presente clave de activación es <b>única</b> e <b>intransferible</b> y solo funciona para la copia asignada, el compartirla o intentar realizar activaciones de más copias podría generar que se bloqueé perdiendo así cualquier beneficio asociado.
                        </p>
                    </div>
                    </React.Fragment>,
            width:  "100vw",
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            customClass: {
                cancelButton: 'cancelButton',
                confirmButton: 'confirmButton',
                closeButton: 'closeButton',
                actions: 'actions',
                content: 'content',
                popup: 'show-key',
                htmlContainer: 'htmlContainer',
                title: 'title',
                container: 'show-key-container'
            },
            showClass: {
                popup: `
                  animate__animated
                  animate__slideInRight
                `
            },
            hideClass: {
                popup: `
                  animate__animated
                  animate__slideOutRight
                `
            },
            didOpen: ()=> {
                let bgKey = document.createElement("div");
                bgKey.setAttribute('id', 'bg-key');
                bgKey.classList.add("animate__animated");
                bgKey.classList.add("animate__fadeInRight");
                Array.from(document.getElementsByClassName("show-key-container"))[0].appendChild(bgKey);
                Array.from(document.getElementsByClassName("show-key-container"))[0].classList.add("animate__animated");
                Array.from(document.getElementsByClassName("show-key-container"))[0].classList.add("animate__slideInRight");
            },
            willClose: () => {
                document.getElementById("bg-key").classList.remove("animate__fadeInRight");
                document.getElementById("bg-key").classList.add("animate__fadeOutRight");
                Array.from(document.getElementsByClassName("show-key-container"))[0].classList.remove("swal2-backdrop-hide");
                Array.from(document.getElementsByClassName("show-key-container"))[0].classList.add("animate__slideOutRight");
            }
        });
    };

    return (
        <React.Fragment>
            <div id="productos" className='animate__animated animate__slideInDown'>
                <div id="header">
                    <div>
                        <h1>Productos</h1>
                        <p>Conozca todos nuestros productos</p>
                    </div>
                    <a href="https://kisystems.com.mx/" target="_blank"><i className="fa-solid fa-up-right-from-square"></i>Más información</a>
                </div>
                <div id="carousel">
                    <div className="item animate__animated animate__zoomIn anim-delay-600ms" id="ci-1"></div>
                    <div className="item animate__animated animate__zoomIn anim-delay-800ms" id="ci-2"></div>
                    <div className="item animate__animated animate__zoomIn anim-delay-1s" id="ci-3"></div>
                </div>
            </div>

            <div id="compras" className='animate__animated animate__zoomIn'>
                <div id="header">
                    <h1>Mis compras</h1>
                    <p>Se enlistan todos los productos que ha adquirido</p>
                </div>

                <div id="tablaCompras">
                    <table>
                        <thead>
                            <tr>
                                <th className='producto-th'><span>Producto</span></th>
                                <th className='plataforma-th'><span>Plataforma</span></th>
                                <th className='version-th'><span>Versión</span></th>
                                <th className='fecha-th'><span>Fecha de compra</span></th>
                                <th className='botones-th'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataCompras.map(compra => {
                                    return(
                                        <tr key={compra.id}>
                                            <td className='producto-td'><span>{compra.producto.nombre}</span></td>
                                            <td className='plataforma-td'><span>{compra.producto.plataforma}</span></td>
                                            <td className='version-td'><span>{compra.producto.version}</span></td>
                                            <td className='fecha-td'><span>{compra.fecha}</span></td>
                                            <td className='botones-td'>
                                                <div>
                                                    <button onClick={() => verCopias(compra.id, compra.producto.nombre)}>ACTIVACIÓN <i className="fa-solid fa-key"></i></button>
                                                    <button onClick={e => {
                                                        window.open(compra.producto.descarga);
                                                        popupTitle(e, "Comienza la descarga &nbsp;<i class='fa-solid fa-download'></i>", 3000);
                                                    }}>
                                                        DESCARGAR <i className="fa-solid fa-download"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Compras;