import React from 'react';
import { useState, useEffect } from 'react';
import './styleAdministracion.css';
import { useNavigate } from "react-router-dom";

import DataTable, { createTheme } from 'react-data-table-component';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import ks_icon from '../../images/logoKS_min.png';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ThemeProvider, createTheme as createMUITheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';

import popupTitle from '../../Utils.js';
import { auth, auth_aux } from '../../Firebase.js';
import { signOut, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from '../../Firebase.js';
import { collection, query, where, getDocs, addDoc, doc, setDoc, updateDoc, Timestamp, getDoc } from "firebase/firestore";

const Administracion = () => {
    const navigate = useNavigate();
    const showAlertLoading = () => {
        Swal.fire({
            title: "Conectando con servicio...",
            html: "<div class='loader'></div>",
            width: '400px', allowOutsideClick: false, showConfirmButton: false,
            customClass: { popup: 'popup-loading', htmlContainer: 'htmlContainer', title: 'title' },
            showClass: { popup: "" }, hideClass: { popup: "" }
        });
    };

    useEffect(() => {
        document.title ="Ki Systems | Administración";
        document.getElementsByTagName("html")[0].classList = "";
        document.getElementsByTagName("html")[0].classList.add("styleAdministracion");
        auth.onAuthStateChanged(async user => {
            if (user) {
                const results = await getDocs(query(collection(db, "usuarios"), where("id_usuario", "==", user.uid)));
                switch (results.docs[0].data().tipo_usuario) {
                    case "cliente": navigate("/clientes/compras", { replace: true }); break;
                    case "administrador": reloadDataClientes(); loadDataProductos(); break;
                }
            } else { navigate("/clientes", { replace: true }); }
        });
    }, []);

    //---Show ID---
    const showId = (id) => {
        withReactContent(Swal).fire({
            title:  "Identificador de registro",
            html:   <React.Fragment>
                        <div className='id-container'>
                            <span>{id}</span>
                            <button className='pressed' title='Copiar' onClick={async (e) => {
                                try {
                                    await navigator.clipboard.writeText(id);
                                    popupTitle(e, "Identificador copiado &nbsp;<i class='fa-regular fa-copy'></i>", 2000);
                                } catch (err) {
                                    console.error('Error al copiar: ', err);
                                }
                            }}>
                                <i className="fa-regular fa-copy"></i>
                            </button>
                        </div>
                    </React.Fragment>,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            width: "700px",
            customClass: {
                cancelButton: 'cancelButton',
                confirmButton: `pressed confirmButton`,
                closeButton: `pressed closeButton`,
                actions: 'actions',
                content: 'content',
                popup: 'ver-id',
                htmlContainer: 'htmlContainer',
                title: 'title',
                footer: 'footer'
            },
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInLeft
                  animate__faster
                `
            }
        });
    };

    //--- Datos DB ---
    const [dataClientes, setDataClientes] = React.useState([]);
    const [dataCompras, setDataCompras] = useState([]);
    const [dataClaves, setDataClaves] = useState([]);
    const [dataProductos, setDataProductos] = useState([]);

    const reloadDataClientes = () => {
        showAlertLoading();
        getDocs(collection(db, "clientes")).then(docs => {
            let clientes = [];
            docs.forEach(doc => { let data = doc.data(); clientes.push({ ...data, id: doc.id }); });
            setDataClientes(clientes);
            Swal.close();
        });
    };

    const reloadDataCompras = (idCliente) => {
        setDataCompras([]);
        showAlertLoading();
        getDocs(query(collection(db, "compras"), where("cliente", "==", doc(db, "clientes", idCliente)))).then(async docs => {
            let compras = await Promise.all(
                Array.from(docs.docs).map(async doc => {
                    let data = doc.data();
                    return {
                        producto: (await getDoc(data.producto)).data().nombre,
                        fecha: moment(data.fecha.toDate()).format('DD-MM-YYYY'),
                        pago: "$"+data.pago.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                        id: doc.id
                    };
                })
            );
            setDataCompras(compras);
            Swal.close();
        });
    };

    const reloadDataClaves = (idCompra) => {
        setDataClaves([]);
        showAlertLoading();
        getDocs(query(collection(db, "claves"), where("compra", "==", doc(db, "compras", idCompra)))).then(async docs => {
            let claves = await Promise.all(
                Array.from(docs.docs).map(async doc => {
                    let data = doc.data();
                    return {
                        clave: data.clave,
                        estatus: (await getDoc(data.estatus)).data().nombre,
                        idHardware: (data.idHardware)?data.idHardware:"———",
                        datosHardware: (data.datosHardware)?data.datosHardware:"———",
                        fecha: (data.fecha)?moment(data.fecha.toDate()).format('DD-MM-YYYY'):"———",
                        reasignaciones: data.reasignaciones,
                        fallas: data.fallas,
                        id: doc.id
                    };
                })
            );
            setDataClaves(claves);
            Swal.close();
        });
    };

    const loadDataProductos = () => {
        showAlertLoading();
        getDocs(collection(db, "productos")).then(docs => {
            let productos = [];
            docs.forEach(doc => { let data = doc.data(); productos.push({ ...data, id: doc.id }); });
            setDataProductos(productos);
            Swal.close();
        });
    };

    //--- Tablas ---
    createTheme( 'principal', { text: { primary: '#e6eaee' }, background: { default: 'rgb(48, 55, 59)' } }, 'dark' );
    createTheme( 'secondary', { text: { primary: '#e6eaee' }, background: { default: 'transparent' } }, 'dark' );
    createTheme( 'third', { text: { primary: '#e6eaee' }, background: { default: 'transparent' } }, 'dark' );
    const [currentRowPrincipal, setCurrentRowPrincipal] = useState(null);
    const [currentRowSecondary, setCurrentRowSecondary] = useState(null);

    //--- Tema MUI ---
    const darkTheme = createMUITheme({ palette: { mode: 'dark' } });

    //--- Menú desplegable ---
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tempRow, setTempRow] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const saveRow = (row) => setTempRow(row);

    //--- Estilos de error formularios ---
    const setErrorStyleInput = (name) => {
        document.getElementById(name + "-helper-text").style.visibility = "visible";
        document.getElementById(name + "-label").style.color = "#ff8c84";
        if (document.getElementById(name).parentElement.getElementsByTagName("style").length == 0) {
            let style = document.createElement('style');
            style.textContent = `
                    div:has(> #${name}) {
                        &:after, &:before {
                            border-color: #ff8c84;
                        }
                    }
                `;
            document.getElementById(name).parentElement.appendChild(style);
        }
    };

    const removeErrorStyleInput = (name) => {
        document.getElementById(name + "-helper-text").style.visibility = "hidden";
        document.getElementById(name + "-label").style.color = null;
        if (document.getElementById(name).parentElement.getElementsByTagName("style").length != 0) {
            document.getElementById(name).parentElement.getElementsByTagName("style")[0].remove();
        }
    };

    //--- Formulario registrar cliente ---
    const [sendFRC, setSendFRC] = useState(false);
    const [updateFRC, setUpdateFRC] = useState(false);
    //Nombre
    const [nombre, setNombre] = useState("");
    const [nombreError, setNombreError] = useState(true);
    const handleNombreChange = (e) => {
        setNombre(e.target.value);
        if(e.target.validity.valid) { setNombreError(false); removeErrorStyleInput("nombreITF"); } 
        else { setNombreError(true); setErrorStyleInput("nombreITF"); }
    };
    //Correo
    const [correo, setCorreo] = useState("");
    const [correoError, setCorreoError] = useState(true);
    const handleCorreoChange = (e) => {
        setCorreo(e.target.value);
        if(e.target.validity.valid) { setCorreoError(false); removeErrorStyleInput("correoITF"); } 
        else { setCorreoError(true); setErrorStyleInput("correoITF"); }
    };
    //Teléfono
    const [telefono, setTelefono] = useState("");
    const [telefonoError, setTelefonoError] = useState(true);
    const handleTelefonoChange = (e) => {
        setTelefono(e.target.value);
        if(e.target.validity.valid) { setTelefonoError(false); removeErrorStyleInput("telefonoITF"); } 
        else { setTelefonoError(true); setErrorStyleInput("telefonoITF"); }
    };
    //Contraseña
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(true);
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if(e.target.validity.valid) { setPasswordError(false); removeErrorStyleInput("passwordITF"); } 
        else { setPasswordError(true); setErrorStyleInput("passwordITF"); }
    };

    const resetFormRegistrarCliente = (cliente) => {
        //Edición
        if(cliente) {
            setNombre(""); setNombreError(false); removeErrorStyleInput("nombreITF");
            setCorreo(""); setCorreoError(false); removeErrorStyleInput("correoITF");
            setTelefono(""); setTelefonoError(false); removeErrorStyleInput("telefonoITF");
            setPasswordError(false);
            Swal.getConfirmButton().disabled = false;
        } else {
            //Registro
            setNombre(""); setNombreError(true); removeErrorStyleInput("nombreITF");
            setCorreo(""); setCorreoError(true); removeErrorStyleInput("correoITF");
            setTelefono(""); setTelefonoError(true); removeErrorStyleInput("telefonoITF");
            setPassword(""); setPasswordError(true); removeErrorStyleInput("passwordITF");
        }
    };

    useEffect(() => {
        if(sendFRC) {
            showAlertLoading();

            if(nombre.trim() == "" || correo.trim() == "" || telefono.trim() == "") {
                Swal.close();
                popupTitle(null, "Datos incorrectos, verifique &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A");
            } else {
                createUserWithEmailAndPassword(auth_aux, correo, password).then(async userCredential => {
                    await setDoc(doc(db, "usuarios", userCredential.user.uid), {
                        id_usuario: userCredential.user.uid,
                        tipo_usuario: "cliente"
                    });
    
                    await setDoc(doc(db, "clientes", userCredential.user.uid), {
                        nombre: nombre.trim(),
                        correo: correo.trim(),
                        telefono: telefono.trim()
                    });
    
                    signOut(auth_aux).then(() => {
                        Swal.close();
                        reloadDataClientes();
                        popupTitle(null, "Registro completado &nbsp;<i class='fa-solid fa-circle-check'></i>", 2500, "#AAFFF7", "#04413B");
                    }).catch(error => console.log(error));
                }).catch(error => console.log(error));
            }

            setSendFRC(false);//Se resetea varible de envío
        }
    }, [sendFRC]);

    useEffect(() => {
        if(updateFRC) {
            showAlertLoading();
            if((nombre.trim() != "" && nombre.trim() != tempRow.nombre) || (telefono.trim() != "" && telefono.trim() != tempRow.telefono) || (correo.trim() != "" && correo.trim() != tempRow.correo)) {
                if(correo.trim() != "" && correo.trim() != tempRow.correo) {
                    fetch("https://us-central1-kisystems-d4135.cloudfunctions.net/updateEmail?uid="+tempRow.id+"&email="+((correo.trim() != "")?correo.trim():tempRow.correo))
                    .then(response => response.json()).then(data => {
                        if(data.completado) {
                            updateDoc(doc(db, "clientes", tempRow.id), {
                                nombre: (nombre.trim() != "")?nombre.trim():tempRow.nombre,
                                correo: (correo.trim() != "")?correo.trim():tempRow.correo,
                                telefono: (telefono.trim() != "")?telefono.trim():tempRow.telefono
                            }).then(() => {
                                Swal.close();
                                reloadDataClientes();
                                popupTitle(null, "Actualización realizada &nbsp;<i class='fa-solid fa-circle-check'></i>", 2500, "#AAFFF7", "#04413B");
                            });
                        } else {
                            Swal.close();
                            popupTitle(null, "Error al actualizar el correo de la cuenta &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A");
                        }
                    }).catch(() => {
                        Swal.close();
                        popupTitle(null, "Error al acceder a servicio &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A");
                    });
                } else {
                    updateDoc(doc(db, "clientes", tempRow.id), {
                        nombre: (nombre.trim() != "")?nombre.trim():tempRow.nombre,
                        correo: (correo.trim() != "")?correo.trim():tempRow.correo,
                        telefono: (telefono.trim() != "")?telefono.trim():tempRow.telefono
                    }).then(() => {
                        Swal.close();
                        reloadDataClientes();
                        popupTitle(null, "Actualización realizada &nbsp;<i class='fa-solid fa-circle-check'></i>", 2500, "#AAFFF7", "#04413B");
                    });
                }
            } else {
                Swal.close();
                popupTitle(null, "Registro sin cambios &nbsp;<i class='fa-solid fa-circle-check'></i>", 2500, "#AAFFF7", "#04413B");
            }

            setUpdateFRC(false);//Se resetea varible de envío
        }
    }, [updateFRC]);

    useEffect(() => {
        if(Swal.isVisible()) {
            if(!nombreError && !correoError && !telefonoError && !passwordError) {
                Swal.getConfirmButton().disabled = false;
            } else {
                Swal.getConfirmButton().disabled = true;
            }
        }
    }, [nombreError, correoError, telefonoError, passwordError]);

    const registrarCliente = (cliente) => {
        withReactContent(Swal).fire({
            title:  (cliente)?"Edición de cliente":"Registro de cliente",
            html:   <React.Fragment>
                        <ThemeProvider theme={darkTheme}>
                            <CssBaseline />
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch', margin: '20px' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        id="nombreITF"
                                        required
                                        label="Nombre completo"
                                        variant="filled"
                                        onChange={handleNombreChange}
                                        helperText="Campo solo acepta letras y espacios"
                                        inputProps={{ pattern: "^[a-zA-ZÀ-ÿ \s]{1,250}$" }}
                                        defaultValue={(cliente)?cliente.nombre:''}
                                    />
                                    <TextField
                                        id="correoITF"
                                        required
                                        label="Correo electrónico"
                                        variant="filled"
                                        onChange={handleCorreoChange}
                                        helperText="Ingrese un correo válido"
                                        inputProps={{ pattern: "^[a-zA-Z0-9_.]+@[a-zA-Z0-9]+\.[a-zA-Z0-9.]+$" }}
                                        defaultValue={(cliente)?cliente.correo:''}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        id="telefonoITF"
                                        required
                                        type="number"
                                        label="Teléfono"
                                        variant="filled"
                                        onChange={handleTelefonoChange}
                                        helperText="Ingrese un número válido"
                                        inputProps={{ pattern: "^[0-9]{10,13}$" }}
                                        defaultValue={(cliente)?cliente.telefono:''}
                                    />
                                    {(() => {
                                        if(!cliente) {
                                            return (
                                                <TextField
                                                    id="passwordITF"
                                                    required
                                                    type="password"
                                                    label="Contraseña"
                                                    variant="filled"
                                                    onChange={handlePasswordChange}
                                                    helperText="Contraseña muy corta"
                                                    inputProps={{ pattern: "^.{6,70}$" }}
                                                />
                                            );
                                        }
                                    })()}
                                </div>
                            </Box>
                        </ThemeProvider>
                    </React.Fragment>,
            showCancelButton: false,
            showConfirmButton: true,
            showCloseButton: true,
            width: "800px",
            confirmButtonText: "<i class='fa-solid fa-floppy-disk'></i> &nbsp;Guardar",
            allowOutsideClick: false,
            customClass: {
                cancelButton: 'cancelButton',
                confirmButton: `pressed confirmButton`,
                closeButton: `pressed closeButton`,
                actions: 'actions',
                content: 'content',
                popup: 'registrar-cliente',
                htmlContainer: 'htmlContainer',
                title: 'title',
                footer: 'footer'
            },
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInDown
                  animate__faster
                `
            },
            hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutUp
                  animate__faster
                `
            },
            didOpen: () => {
                Swal.getConfirmButton().disabled = true;
                resetFormRegistrarCliente(cliente);
                if(!cliente) { document.getElementById("nombreITF").focus(); }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(cliente) {
                    setUpdateFRC(true);
                } else {
                    setSendFRC(true);
                }
            }
        });
    };

    //--- Formulario registrar compra ---
    const [sendFRB, setSendFRB] = useState(false);

    //Producto
    const [producto, setProducto] = useState("");
    const [productoError, setProductoError] = useState(true);
    const handleProductoChange = (e) => {
        setProducto(e.target.value);
        setProductoError(false);
    };
    //Fecha
    const [fecha, setFecha] = useState(toDateInputValue(new Date()));
    const [fechaError, setFechaError] = useState(false);
    const handleFechaChange = (e) => {
        setFecha(e.target.value);
        (e.target.value)?setFechaError(false):setFechaError(true);
    };
    //Copias
    const [copias, setCopias] = useState(1);
    const [copiasError, setCopiasError] = useState(false);
    const handleCopiasChange = (e) => {
        setCopias(e.target.value);
        (e.target.value != '')?setCopiasError(false):setCopiasError(true);
    };
    //Pago
    const [pago, setPago] = useState(null);
    const [pagoError, setPagoError] = useState(true);
    const handlePagoChange = (e) => {
        setPago(e.target.value);
        (e.target.value != '')?setPagoError(false):setPagoError(true);
    };

    const resetFormRegistrarCompra = () => {
        setProducto(""); setProductoError(true);
        setFecha(toDateInputValue(new Date())); setFechaError(false);
        setCopias(1); setCopiasError(false);
        setPago(null); setPagoError(true);
    };

    useEffect(() => {
        if(sendFRB) {
            showAlertLoading();
            addDoc(collection(db, "compras"), {
                cliente: doc(db, "clientes", tempRow.id),
                producto: doc(db, "productos", producto),
                fecha: Timestamp.fromDate(new Date(fecha.replace(/-/g, '\/'))),
                pago: parseFloat(pago)
            }).then(async refCompra => {
                let promisesClaves = [];
                for (var i = 0; i < parseInt(copias); i++) {
                    promisesClaves.push(() => 
                        addDoc(collection(db, "claves"), {
                            clave: crypto.randomUUID(),
                            estatus: doc(db, "estatus", "1"),
                            compra: refCompra,
                            reasignaciones: 0,
                            fallas: 0
                        })
                    );
                }
                await Promise.all( promisesClaves.map(async insert => await insert()) );
                reloadDataCompras(tempRow.id);
                Swal.close();
            });
            setSendFRB(false);//Se resetea varible de envío
        }
    }, [sendFRB]);

    useEffect(() => {
        if(Swal.isVisible()) {
            if(!productoError && !fechaError && !copiasError && !pagoError) {
                Swal.getConfirmButton().disabled = false;
            } else {
                Swal.getConfirmButton().disabled = true;
            }
        }
    }, [productoError, fechaError, copiasError, pagoError]);

    const registrarCompra = (cliente) => {
        withReactContent(Swal).fire({
            title:  "Registro de compra",
            html:   <React.Fragment>
                        <div className='client-data'>
                            <h3>Cliente</h3>
                            <p>{cliente.nombre}</p>
                            <span>{cliente.correo}</span>
                        </div>
                        <ThemeProvider theme={darkTheme}>
                            <CssBaseline />
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch', margin: '20px' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 'calc(100% - 130px)' }}>
                                        <InputLabel id="label-productoFRC">Producto</InputLabel>
                                        <Select
                                        labelId="label-productoFRC"
                                        id="productoFRC"
                                        defaultValue={''}
                                        onChange={handleProductoChange}
                                        children={
                                            dataProductos.map(producto => {
                                                return <MenuItem key={producto.id} value={producto.id}>{producto.nombre}</MenuItem>
                                            })
                                        }
                                        />
                                    </FormControl>
                                </div>
                                <div>
                                    <TextField
                                        id="fechaFRC"
                                        required
                                        label="Fecha de la compra"
                                        variant="filled"
                                        type='date'
                                        defaultValue={toDateInputValue(new Date())}
                                        onChange={handleFechaChange}
                                    />

                                    <TextField
                                        id="copiasFRC"
                                        required
                                        label="Copias adquiridas"
                                        variant="filled"
                                        type='number'
                                        defaultValue={1}
                                        onChange={handleCopiasChange}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        id="pagoFRC"
                                        required
                                        label="Pago recibido"
                                        variant="filled"
                                        type='number'
                                        onChange={handlePagoChange}
                                        style={{'width':'calc(100% - 130px)'}}
                                    />
                                </div>
                            </Box>
                        </ThemeProvider>
                    </React.Fragment>,
            showCancelButton: false,
            showConfirmButton: true,
            showCloseButton: true,
            width: "800px",
            confirmButtonText: "<i class='fa-solid fa-floppy-disk'></i> &nbsp;Guardar",
            allowOutsideClick: false,
            customClass: {
                cancelButton: 'cancelButton',
                confirmButton: `pressed confirmButton`,
                closeButton: `pressed closeButton`,
                actions: 'actions',
                content: 'content',
                popup: 'registrar-compra',
                htmlContainer: 'htmlContainer',
                title: 'title',
                footer: 'footer'
            },
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInDown
                  animate__faster
                `
            },
            hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutUp
                  animate__faster
                `
            },
            didOpen: () => {
                resetFormRegistrarCompra();
                Swal.getConfirmButton().disabled = true;
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setSendFRB(true);
            }
        });
    };

    function toDateInputValue(dateObject){
        const local = new Date(dateObject);
        local.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    };

    return (
        <React.Fragment>
            <div className='header-top'>
                <div>
                    <img src={ks_icon} alt="Logo KS"/>
                    <span>Administración</span>
                </div>
                <button 
                    className='logout-button pressed custom-button circle-button'
                    onClick={(e) => {
                        signOut(auth).then(() => {
                            navigate("/clientes", { replace: true });
                        }).catch(() => {
                            popupTitle(e, "Error al cerrar sesión &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 2000, "#ff8c84", "#65100A");
                        });
                    }}
                    title='Cerrar sesión'
                >
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                </button>
            </div>

            <div id='clientes-container'>
                <div className='header'>
                    <div>
                        <h1>Clientes</h1>
                        <span>Listado de clientes registrados y compras asociadas</span>
                    </div>
                    <div>
                        <button className='custom-button circle-button' style={{"marginRight": "15px"}} title='Actualizar' onClick={() => reloadDataClientes()}><i className="fa-solid fa-rotate-right"></i></button>
                        <button className='custom-button circle-button' title='Registrar cliente' onClick={() => registrarCliente()}><i className="fa-solid fa-user-plus"></i></button>
                    </div>
                </div>

                <div className='tables'>
                    <DataTable
                        className='principal-table'
                        columns={[
                            { name: 'Nombre', selector: row => row.nombre, sortable: true },
                            { name: 'Correo electronico', selector: row => row.correo, sortable: true },
                            { name: 'Teléfono', selector: row => row.telefono, sortable: true },
                            {
                                cell: (row) => 
                                    <React.Fragment>
                                        <ThemeProvider theme={darkTheme}>
                                            <CssBaseline />
                                            <IconButton
                                                aria-label="more"
                                                id={"menu-button-"+row.id}
                                                className="table-menu-button"
                                                aria-controls={open ? 'long-menu'+row.id : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={(e) => { handleClick(e); saveRow(row); }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id={"long-menu"+row.id}
                                                MenuListProps={{ 'aria-labelledby': "menu-button-"+row.id }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={() => {
                                                    handleClose();
                                                    registrarCompra(tempRow);
                                                }}>
                                                    <div className='registrar'>
                                                        <i className="fa-solid fa-cart-shopping"></i>
                                                        <span>&nbsp;&nbsp;&nbsp; Registrar compra</span>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleClose();
                                                    showId(tempRow.id);
                                                }}>
                                                    <div className='ver'>
                                                        <i className="fa-solid fa-database"></i>
                                                        <span>&nbsp;&nbsp;&nbsp; Ver id</span>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleClose();
                                                    registrarCliente(tempRow);
                                                }}>
                                                    <div className='editar'>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                        <span>&nbsp;&nbsp;&nbsp; Editar</span>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleClose();
                                                    withReactContent(Swal).fire({
                                                        title:  "Restauración de contraseña",
                                                        html:   <React.Fragment>
                                                                    <div className='client-data'>
                                                                        <h3>Cliente</h3>
                                                                        <p>{tempRow.nombre}</p>
                                                                        <span>{tempRow.correo}</span>
                                                                    </div>
                                                                    <ThemeProvider theme={darkTheme}>
                                                                        <CssBaseline />
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch', margin: '20px' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >
                                                                            <div>
                                                                                <TextField
                                                                                    id="newPassword"
                                                                                    required
                                                                                    type="password"
                                                                                    label="Nueva contraseña"
                                                                                    variant="filled"
                                                                                    onChange={(e) => {
                                                                                        if(e.target.validity.valid) { 
                                                                                            Swal.getConfirmButton().disabled = false;
                                                                                            removeErrorStyleInput("newPassword");
                                                                                        } 
                                                                                        else { 
                                                                                            Swal.getConfirmButton().disabled = true;
                                                                                            setErrorStyleInput("newPassword");
                                                                                        }
                                                                                    }}
                                                                                    helperText="Contraseña muy corta"
                                                                                    inputProps={{ pattern: "^.{6,70}$" }}
                                                                                />
                                                                            </div>
                                                                        </Box>
                                                                    </ThemeProvider>
                                                                </React.Fragment>,
                                                        showCancelButton: false,
                                                        showConfirmButton: true,
                                                        showCloseButton: true,
                                                        width: "600px",
                                                        confirmButtonText: "<i class='fa-solid fa-floppy-disk'></i> &nbsp;Actualizar",
                                                        allowOutsideClick: false,
                                                        customClass: {
                                                            cancelButton: 'cancelButton',
                                                            confirmButton: `pressed confirmButton`,
                                                            closeButton: `pressed closeButton`,
                                                            actions: 'actions',
                                                            content: 'content',
                                                            popup: 'registrar-compra',
                                                            htmlContainer: 'htmlContainer',
                                                            title: 'title',
                                                            footer: 'footer'
                                                        },
                                                        showClass: {
                                                            popup: `
                                                              animate__animated
                                                              animate__fadeInDown
                                                              animate__faster
                                                            `
                                                        },
                                                        hideClass: {
                                                            popup: `
                                                              animate__animated
                                                              animate__fadeOutUp
                                                              animate__faster
                                                            `
                                                        },
                                                        didOpen: () => {
                                                            removeErrorStyleInput("newPassword");
                                                            document.getElementById("newPassword").focus();
                                                            Swal.getConfirmButton().disabled = true;
                                                        }
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            let newPassword = document.getElementById("newPassword").value;
                                                            showAlertLoading();
                                                            fetch("https://us-central1-kisystems-d4135.cloudfunctions.net/updatePassword?uid="+tempRow.id+"&pass="+newPassword)
                                                            .then(response => response.json()).then(data => {
                                                                if(data.completado) {
                                                                    Swal.close();
                                                                    popupTitle(null, "Contraseña actualizada &nbsp;<i class='fa-solid fa-circle-check'></i>", 2500, "#AAFFF7", "#04413B");
                                                                } else {
                                                                    Swal.close();
                                                                    popupTitle(null, "Error al actualizar contraseña &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A");
                                                                }
                                                            }).catch(() => {
                                                                Swal.close();
                                                                popupTitle(null, "Error al acceder a servicio &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A");
                                                            });
                                                        }
                                                    });
                                                }}>
                                                    <div className='restaurar'>
                                                        <i className="fa-solid fa-key"></i>
                                                        <span>&nbsp;&nbsp;&nbsp; Restaurar contraseña</span>
                                                    </div>
                                                </MenuItem>
                                            </Menu>
                                        </ThemeProvider>
                                    </React.Fragment>
                                ,
                                width: '70px'
                            }
                        ]}
                        data={dataClientes}
                        highlightOnHover
                        pagination
                        expandableRows
                        expandOnRowClicked
                        onRowExpandToggled={(isExpand, data) => {
                            if(isExpand) {
                                setCurrentRowPrincipal(data);
                                reloadDataCompras(data.id);
                            }
                        }}
                        expandableRowExpanded={row => row === currentRowPrincipal}
                        expandableRowsComponent={() => {
                            return (
                                <DataTable
                                    className='secondary-table margin-table'
                                    columns={[
                                        { name: 'Producto', selector: row => row.producto, sortable: true },
                                        { name: 'Fecha de compra', selector: row => row.fecha, sortable: true },
                                        { name: 'Pago', selector: row => row.pago, sortable: true },
                                        { cell: (row) => <i className="fa-solid fa-database iconButtonTable" onClick={() => showId(row.id)} title='Ver id'></i> }
                                    ]}
                                    data={dataCompras}
                                    highlightOnHover
                                    expandableRows
                                    expandOnRowClicked
                                    onRowExpandToggled={(isExpand, sdata) => {
                                        if(isExpand) {
                                            setCurrentRowSecondary(sdata);
                                            reloadDataClaves(sdata.id);
                                        }
                                    }}
                                    expandableRowExpanded={rows => rows === currentRowSecondary}
                                    expandableRowsComponent={() => {
                                        return (
                                            <DataTable
                                                className='third-table margin-table'
                                                columns={[
                                                    { name: 'Clave de activación', selector: row => row.clave, sortable: true },
                                                    { name: 'Estatus', selector: row => row.estatus, sortable: true },
                                                    { name: 'Clave hardware', selector: row => row.idHardware, sortable: true },
                                                    { name: 'Datos hardware', selector: row => row.datosHardware, sortable: true },
                                                    { name: 'Fecha de activación', selector: row => row.fecha, sortable: true },
                                                    { name: 'Reasignaciones', selector: row => row.reasignaciones, sortable: true },
                                                    { name: 'Fallas de activación', selector: row => row.fallas, sortable: true },
                                                    { cell: (row) => <i className="fa-solid fa-database iconButtonTable" onClick={() => showId(row.id)} title='Ver id'></i> }
                                                ]}
                                                data={dataClaves}
                                                highlightOnHover
                                                theme="third"
                                            />
                                        );
                                    }}
                                    theme="secondary"
                                />
                            );
                        }}
                        theme="principal"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Administracion;