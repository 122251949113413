import React from 'react';
import './style.css';

import logoKS from '../../images/logoKS.png';
import logoKSmin from '../../images/logoKS_min.png';
import firework from '../../images/inicio/firework.png';
import people from '../../images/inicio/people.png';
import convocatoria from '../../documents/cks1_convocatoria.pdf';
import {Link} from "react-router-dom";
import { analytics } from '../../Firebase.js';
import { logEvent } from "firebase/analytics";

class Inicio extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

    cuentaRegresivaCKS;
    scrollEvent = (event) => {
        if (document.documentElement.getBoundingClientRect().top !== 0) {
            document.getElementsByTagName("nav")[0].style.backgroundColor = "#3C484F";
        } else {
            document.getElementsByTagName("nav")[0].style.backgroundColor = "rgba(255, 255, 255, 0.035)";
        }
    };
    bodyScrollEvent = (event) => {
        if(document.getElementById("minimal-menu").getBoundingClientRect().top < 0) {
            document.getElementById("up-sc-btn").style.display = "block";
            document.getElementById("up-sc-btn").classList.remove("animate__fadeOut");
            document.getElementById("up-sc-btn").classList.add("animate__fadeIn");
        } else {
            document.getElementById("up-sc-btn").classList.remove("animate__fadeIn");
            document.getElementById("up-sc-btn").classList.add("animate__fadeOut");
            setTimeout(() => {
                document.getElementById("up-sc-btn").style.display = "none";
            }, 300);
        }
    };

	componentDidMount() {
        document.title ="Ki Systems | Inicio";
        document.getElementsByTagName("html")[0].classList = "";
        document.getElementsByTagName("html")[0].classList.add("styleInicio");

        document.addEventListener("scroll", this.scrollEvent);

        document.getElementsByTagName("body")[0].addEventListener("scroll", this.bodyScrollEvent);

        const newYearEndDate = new Date('Feb 10 2024 10:00:00');
        const  formatTime = (time) => { return time < 10 ? `0${time}` : time; };

        /*const countdown = () => {
            const totalSeconds = (newYearEndDate - (new Date())) / 1000;
            document.querySelector("#days").innerHTML = formatTime(Math.floor(totalSeconds / 3600 / 24));
            document.querySelector("#hours").innerHTML = formatTime(Math.floor(totalSeconds / 3600) % 24);
            document.querySelector("#minutes").innerHTML = formatTime(Math.floor(totalSeconds / 60) % 60);
            document.querySelector("#seconds").innerHTML = formatTime(Math.floor(totalSeconds % 60));
        };

        countdown();
        this.cuentaRegresivaCKS = setInterval(countdown,1000);*/
	}

    componentWillUnmount() {
        clearInterval(this.cuentaRegresivaCKS);
        document.getElementsByTagName("body")[0].removeEventListener("scroll", this.bodyScrollEvent);
        document.removeEventListener("scroll", this.scrollEvent);
    }
      
    carracor = (index, productos) => {
        Array.from(productos.children).forEach((e, i) => {
            if (i !== index) {
                e.classList.remove("active");
            } else {
                e.classList.add("active");
            }
        });
    };

	render() {
		return(
			<React.Fragment>
                <nav>
                    <ul>
                        <li><a href="#nosotros" className="animate__animated animate__fadeInDown animate__slower">Productos</a></li>
                        <li><a href="#torneo" className="animate__animated animate__fadeInDown animate__slower">Eventos</a></li>
                        <li className="stc"><a href="#home" className="animate__animated animate__fadeInDown animate__slower"><img src={logoKS} alt="KiSystems Logo" /></a></li>
                        <li><Link to="/servicios" className="animate__animated animate__fadeInDown animate__slower">Servicios</Link></li>
                        <li><a href="#contacto" className="animate__animated animate__fadeInDown animate__slower">Contacto</a></li>
                    </ul>
                </nav>

                <div id='minimal-menu'>
                    <div className='top-menu'>
                        <div id='logo-mm'><img src={logoKSmin} alt="KiSystems Logo" /></div>
                        <a id='button-mm' href='#serviciosInfoSm'>
                            <h3>¡Nueva división!</h3>
                            <h4>Ki Systems</h4>
                            <h5>Servicios</h5>
                            <h6>Desarrollo web, diseño gráfico y más</h6>
                        </a>
                        <i class="fa-solid fa-bars" onClick={(e) => e.target.parentElement.parentElement.classList.toggle("max-min-menu")}></i>
                    </div>
                    
                    <div className='container-menu'>
                        <a href="#nosotros">Productos</a>
                        <a href="#torneo">Eventos</a>
                        <Link to="/servicios">Servicios</Link>
                        <a href="#contacto">Contacto</a>
                    </div>
                </div>

                <header className="section" id="home">
                    <div id="torneoInfoSm" className="animate__animated animate__fadeInLeft">
                        <i class="fa-solid fa-trophy"></i>
                        <h1>Ki Systems <p>Eventos</p></h1>
                        <h3>1er Torneo nacional abierto de Taekwondo</h3>
                        <h4>Faltan:</h4>
                        <div id="timeCardsContainer">
                            <div>
                                <h1 id="days">00</h1>
                                <h5>DÍAS</h5>
                            </div>

                            <div>
                                <h1 id="hours">00</h1>
                                <h5>HORAS</h5>
                            </div>

                            <div>
                                <h1 id="minutes">00</h1>
                                <h5>MINS.</h5>
                            </div>

                            <div>
                                <h1 id="seconds">00</h1>
                                <h5>SEGS.</h5>
                            </div>
                        </div>
                        <a href="#torneo" onClick={() => logEvent(analytics, "Click en convocatoria")}>Convocatoria</a>
                    </div>

                    <div id="marcadorInfoSm" className="animate__animated animate__fadeInUp">
                        <h1>Ki Systems <p>Productos</p></h1>

                        <h3>SMTKD® <br/><b>Sistema de marcación integral</b></h3>
                        <a href="#nosotros" onClick={() => logEvent(analytics, "Click en productos")}>Ver más</a>

                        <h3>KiCup® <br/><b>Sistema online gestor de torneos por evento</b></h3>
                        <a href="#">Acceder</a>
                    </div>

                    <div id="serviciosInfoSm" className="animate__animated animate__fadeInRight">
                        <h1>Ki Systems <p>Servicios</p></h1>

                        <div>
                            <i class="fa-solid fa-laptop-code"></i>
                            <h3>Construimos su sitio web <br/><b>Totalmente personalizado</b></h3>
                        </div>
                        <div>
                            <i class="fa-solid fa-server"></i>
                            <h3>Sistemas web y aplicaciones móviles <br/><b>Seguros, configurables y escalables</b></h3>
                        </div>
                        <div>
                            <i class="fa-regular fa-image"></i>
                            <h3>Diseño gráfico <br/><b>Publicidad, identidad gráfica empresarial y más</b></h3>
                        </div>
                        
                        <Link className='animate__animated animate__tada animate__repeat-3' to="/servicios" onClick={() => logEvent(analytics, "Click en servicios")}>Ver más</Link>
                        <h3 className='news-title'>¡Nueva división!</h3>
                    </div>
                </header>

                <main>
                    <div className="section" id="nosotros">
                        <div className="image-bg"></div>
                        <article>
                            <h2>Sistema de marcación integral | SMTKD</h2>
                            Ki systems le ofrece el mejor sistema de marcación integral multiplataforma en Tae Kwon Do, creado y diseñado por profesionales en la Ingeniería de software en colaboración con expertos del arte marcial como deporte olimpico.
                            <br /><br />
                            Ki system es un producto altamente adaptable a lo que el usuario requiera, configurable y compatible a los mandos que mejor apoyen a la ergonomía de su uso.
                            <br /><br />
                            <div>
                                Disponible para:
                                <br />
                                <center>
                                    <i className='bx bxl-windows'></i>
                                    <i className='bx bxl-apple'></i>
                                    <i className='bx bxl-android'></i>
                                </center>
                            </div>
                            <br />
                            <div>
                                Compatible con mandos:
                                <br />
                                <center>
                                    <i className="fa-brands fa-xbox"></i>&nbsp;
                                    <i className="fa-brands fa-playstation"></i>&nbsp;
                                </center>
                            </div>
                        </article>
                    </div>

                    <div className="section" id="productos">
                        <div className="info-card active" id="mod-combate" onClick={(e) => this.carracor(0, e.target.parentElement.parentElement)}>
                            <p className="image-bg"></p>
                            <h1>Módulo de combate</h1>
                            <div>
                                <h2>Módulo de combate</h2>
                                <p>Marcador de combate totalmente personalizable, con estadísticas por round y para uso por 1, 2 o hasta 3 jueces</p>
                            </div>
                        </div>

                        <div className="info-card" id="mod-team" onClick={(e) => this.carracor(1, e.target.parentElement.parentElement)}>
                            <p className="image-bg"></p>
                            <h1>Módulo de combate por equipo</h1>
                            <div>
                                <h2>Módulo de combate por equipo</h2>
                                <p>Marcador de combate por equipo de 2 a 5 participantes, totalmente personalizable, con estadísticas por round y para uso por 1, 2 o hasta 3 jueces</p>
                            </div>
                        </div>

                        <div className="info-card" id="mod-poomsae" onClick={(e) => this.carracor(2, e.target.parentElement.parentElement)}>
                            <p className="image-bg"></p>
                            <h1>Módulo de Poomsae</h1>
                            <div>
                                <h2>Módulo de Poomsae</h2>
                                <p>Marcador de poomsae amateur con métricas por puntos o por decisión, configurable y con conteo preciso</p>
                            </div>
                        </div>

                        <div className="info-card" id="mod-circuito" onClick={(e) => this.carracor(3, e.target.parentElement.parentElement)}>
                            <p className="image-bg"></p>
                            <h1>Módulo de circuito motriz</h1>
                            <div>
                                <h2>Módulo de circuito motriz</h2>
                                <p>Marcador de circuito motriz configurable a decisión por puntos o penalizaciones y con opción a marcación simultánea de dos participantes</p>
                            </div>
                        </div>
                    </div>

                    <div className="section" id="precios">
                        <div id="precios-w1" className="e-card playing">
                            <div className="image"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="infotop">
                                <i className="fa-solid fa-trophy"></i><br />
                                Paquete completo
                                <br />
                                <div className="name">
                                    <t>Módulos</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Combate<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Poomsae<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Team Five<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Circuito motriz<br />
                                    <br />
                                    <t>Características</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Reglamento actualizado WT<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Altamente configurable<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Estadísticas<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Soporte técnico incluido<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Actualizaciones gratis<br />
                                    <br />
                                    <t>Incluye</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Instalación de software<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Mandos inalámbricos<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Adaptador bluetooth<br />
                                    <br />
                                    <b>$ 12,000.00 mxn</b>
                                    <br /><br />
                                </div>
                            </div>
                        </div>

                        <div id="precios-w2" className="e-card playing">
                            <div className="image"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="infotop">
                                <i className="fa-solid fa-award"></i><br />
                                Paquete combate
                                <br />
                                <div className="name">
                                    <t>Módulos</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Combate<br />
                                    <br />
                                    <t>Características</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Reglamento actualizado WT<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Altamente configurable<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Estadísticas por round<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Soporte técnico incluido<br />
                                    <br />
                                    <t>Incluye</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Instalación de software<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Mandos inalámbricos<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Adaptador bluetooth<br />
                                    <br /><br /><br /><br /><br /><br />
                                    <b>$ 7,500.00 mxn</b>
                                    <br /><br />
                                </div>
                            </div>
                        </div>

                        <div id="precios-w3" className="e-card playing">
                            <div className="image"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="infotop">
                                <i className="fa-solid fa-medal"></i><br />
                                Paquete combate simple
                                <br />
                                <div className="name">
                                    <t>Módulos</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Combate<br />
                                    <br />
                                    <t>Características</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Reglamento actualizado WT<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Altamente configurable<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Estadísticas por round<br />
                                    <br />
                                    <t>Incluye</t><br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<d>✓&nbsp;</d> Instalación de software<br />
                                    <br /><br /><br /><br /><br /><br /><br /><br /><br />
                                    <b style={{ paddingTop: "17px" }}>$ 5,000.00 mxn</b>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section" id="torneo">
                        <img src={firework} className="firework" id="firework-1" />
                        <img src={firework} className="firework" id="firework-2" />
                        <img src={firework} className="firework" id="firework-3" />
                        <i className="fa-solid fa-trophy" id="trophy"></i>
                        <img src={people} />
                        <div className="detail">
                            <h1>Copa Ki Systems</h1>
                            <h3><i class="fa-regular fa-calendar-check"></i> 10 de febrero del 2024</h3>
                            <br /><br />
                            <p>Por aniversario de nuestro primer producto: SMTKD® y en agradecimiento a su preferencia, Ki Systems celebrará el torneo: <b>Copa Ki Systems</b>.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i>¡No te quedes fuera!</i></p>
                            <br /><br />
                            {/*<a href={convocatoria} target='_blank'>
                                <div className="opendoc">
                                    <i class="fa-regular fa-file-pdf"></i>
                                    <label>Ver convocatoria</label>
                                </div>
                            </a>*/}
                        </div>
                    </div>

                    <div className="section" id="contacto">
                        <h1>Contacto</h1>

                        <div id="contacto-container-cards">
                            <div className="contact-card">
                                <div className="top-card" id="wa">
                                    <i className="fa-brands fa-whatsapp"></i>
                                    <p>WhatsApp</p>
                                </div>
                                <a href="https://wa.me/+5212227492240" target="_blank">+52 222 74 92 24 0</a><br />
                                <a href="https://wa.me/+5212224173087" target="_blank">+52 222 41 73 08 7</a>
                            </div>

                            <div className="contact-card">
                                <div className="top-card" id="ce">
                                    <i className="fa-solid fa-at"></i>
                                    <p>Correo electrónico</p>
                                </div>
                                <a href="mailto:kisystemssa@gmail.com" target="_blank">kisystemssa@gmail.com</a>
                            </div>
                        </div>

                        <footer>
                            <div>
                                <a href='#home'><i className="fa-solid fa-house"></i></a>
                                <a href='#nosotros'><i className="fa-solid fa-book-open"></i></a>
                                <a href='#productos'><i className="fa-solid fa-box-open"></i></a>
                                <a href='#precios'><i className="fa-solid fa-wallet"></i></a>
                                <a href='#contacto'><i className="fa-solid fa-phone"></i></a>
                            </div>
                            <p>KiSystems Copyright © 2024 - All rights reserved || Designed By: KiSystems</p>
                        </footer>
                    </div>

                    <i
                        id="up-sc-btn"
                        class="fa-solid fa-circle-chevron-up up-scroll-btn animate__animated" style={{display: "none"}}
                        onClick={() => document.getElementsByTagName("body")[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                    ></i>
                </main>
			</React.Fragment>
		);
	}

}

export default Inicio;