import React from "react";
import {BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Inicio from ".//components/inicio/Inicio.js";
import Servicios from ".//components/servicios/Servicios.js";
import ConstanciaPrimeraCopa from ".//components/validaciones/constancias/PrimeraCopa.js";
import LoginClientes from "./components/clientes/Login.js";
import Clientes from "./components/clientes/Clientes.js";
import Administracion from "./components/clientes/Administracion.js";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Inicio />} />
        <Route path="/administracion" exact element={<Administracion />} />
        <Route path="/clientes" exact element={<LoginClientes />} />
        <Route path="/clientes/*" element={<Clientes />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/constancia/1" element={<ConstanciaPrimeraCopa />} />
      </Routes>
    </Router>
  );
};

export default App;