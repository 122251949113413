import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './styleLogin.css';
import logoKSmin from '../../images/logoKS_min.png';
import { auth } from '../../Firebase.js';
import { signInWithEmailAndPassword } from "firebase/auth";
import { db } from '../../Firebase.js';
import { collection, query, where, getDocs } from "firebase/firestore";
import popupTitle from '../../Utils.js';

const Login = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        document.title ="Ki Systems | Acceso clientes";
        document.getElementsByTagName("html")[0].classList = "";
        document.getElementsByTagName("html")[0].classList.add("styleLoginClientes");
        
        auth.onAuthStateChanged(async user => {
            if (user) {
                const results = await getDocs(query(collection(db, "usuarios"), where("id_usuario", "==", user.uid)));
                switch (results.docs[0].data().tipo_usuario) {
                    case "administrador": navigate("/administracion", { replace: true }); break;
                    case "cliente": navigate("/clientes/compras", { replace: true }); break;
                }
            }
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, e.target.children[0].value, e.target.children[1].value)
        .then(async user => {
            const results = await getDocs(query(collection(db, "usuarios"), where("id_usuario", "==", user.uid)));
            switch (results.docs[0].data().tipo_usuario) {
                case "administrador": navigate("/administracion", { replace: true }); break;
                case "cliente": navigate("/clientes/compras", { replace: true }); break;
            }
        })
        .catch(error => {
            switch(error.code) {
                case "auth/user-not-found": popupTitle(e, "Correo no registrado &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A"); break;
                case "auth/wrong-password": popupTitle(e, "La contraseña es incorrecta &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A"); break;
                case "auth/invalid-email": popupTitle(e, "Correo no válido &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A"); break;
                case "auth/invalid-credential": popupTitle(e, "Credenciales no válidas &nbsp;<i class='fa-solid fa-circle-exclamation'></i>", 3000, "#ff8c84", "#65100A"); break;
            }
        });
    };

    return (
        <React.Fragment>
            <div id="main">
                <div id="form" className="animate__animated animate__zoomIn animate__faster">
                    <div id="formContainerTop" className="animate__animated animate__rotateInDownLeft anim-delay-100ms">
                        <div className="triangle" id="tbg-1"></div>
                        <div className="triangle" id="tbg-2"></div>
                        <img className="animate__animated animate__fadeIn anim-delay-500ms" src={logoKSmin}/>
                    </div>
                    <h1 className="animate__animated animate__fadeIn anim-delay-500ms">Acceder</h1>
                    <p className="animate__animated animate__fadeIn anim-delay-600ms">Ingrese las credenciales otorgadas en su compra</p>
                    <form onSubmit={handleSubmit}>
                        <input className="animate__animated animate__fadeIn anim-delay-700ms" type="email" placeholder="Correo electrónico" name="email" required autoComplete="false"/>
                        <input className="animate__animated animate__fadeIn anim-delay-800ms" type="password" placeholder="Contraseña" name="password" required/>
                        <button className="animate__animated animate__bounceIn anim-delay-1s-100ms" type="submit">SIGUIENTE</button>
                    </form>
                    <div id="formContainerBottom" className="animate__animated animate__rotateInUpRight anim-delay-300ms">
                        <div className="triangle" id="tbg-3"></div>
                        <div className="triangle" id="tbg-4"></div>
                    </div>
                </div>
            </div>
            <p className="footerInfo animate__animated animate__fadeIn anim-delay-1s-100ms">KiSystems Copyright © 2024 - All rights reserved</p>
        </React.Fragment>
    );
};

export default Login;