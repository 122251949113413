import React from 'react';
import { useEffect } from 'react';
import './style.css';

const Soporte = () => {
    useEffect(() => {
        document.getElementById("main").scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <React.Fragment>
            <div id="soporte">
                <h1 className='animate__animated animate__slideInDown'>Soporte técnico</h1>
                <h2 className='animate__animated animate__fadeIn anim-delay-300ms'>Preguntas frecuentes</h2>

                <div className='question-container animate__animated animate__zoomIn anim-delay-500ms'>
                    <div>
                        <h3>¿Cómo realizo la activación de mi producto?</h3>
                        <a href='https://youtu.be/nqSnu-RE2Xg?si=W093wWQ2OIn5WjHq' target='_blank'><i className='fa-solid fa-film'></i>Ver vídeo</a>
                    </div>

                    <ol>
                        <li>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accede al apartado de Mis compras</li>
                        <li>2.&nbsp;&nbsp;&nbsp;&nbsp;En la tabla de compras selecciona la que corresponda con el producto que desea activar y presione el botón Activación</li>
                        <li>3.&nbsp;&nbsp;&nbsp;&nbsp;Elige una de las claves de activación disponibles y presione el botón Ver clave</li>
                        <li>4.&nbsp;&nbsp;&nbsp;&nbsp;Copie la clave de activación otorgada dentro de la sección de activación del producto</li>
                    </ol>

                    <p><br/>Listo! Su producto ya ha quedado activado, este proceso solo se realiza una sola vez.</p>
                </div>

                <div className='question-container animate__animated animate__zoomIn anim-delay-600ms'>
                    <div>
                        <h3>¿Puedo cambiar mi producto de dispositivo?</h3>
                        {/*<a href='#'><i className='fa-solid fa-film'></i>Ver vídeo</a>*/}
                    </div>

                    <p>Si, pero primero deberá desactivar el producto en el dispositivo previo, para ello, siga los siguientes pasos:<br/><br/></p>

                    <ol>
                        <li>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dentro del menú principal, acceda a la opción de Información, ubicada en la parte inferior derecha</li>
                        <li>2.&nbsp;&nbsp;&nbsp;&nbsp;Seleccione la opción Desactivar producto y confirme la operación</li>
                        <li>3.&nbsp;&nbsp;&nbsp;&nbsp;Realice el proceso de activación en el nuevo dispositivo</li>
                    </ol>
                </div>

                <div className='question-container animate__animated animate__zoomIn anim-delay-700ms'>
                    <div>
                        <h3>Mi producto se ha bloqueado y no lo puedo utilizar, ¿Cómo lo desbloqueo?</h3>
                        {/*<a href='#'><i className='fa-solid fa-film'></i>Ver vídeo</a>*/}
                    </div>

                    <p>Lamentablemente hemos detectado un mal uso del software por lo que se ha bloqueado de forma permanente, perdiendo así todos los beneficios asociados, las razones por la que esto ocurre son:<br/><br/></p>

                    <ul>
                        <li>-&nbsp;&nbsp;&nbsp;&nbsp;Compartir la llave de activación asociada a la copia</li>
                        <li>-&nbsp;&nbsp;&nbsp;&nbsp;Intentar distribuir una copia activada</li>
                        <li>-&nbsp;&nbsp;&nbsp;&nbsp;Realizar cambio de dispositivo de forma reiterada</li>
                    </ul>
                </div>

                <p className='animate__animated animate__fadeIn anim-delay-800ms'>Si requiere apoyo para un problema con su software, comuníquese con nosotros en</p>

                <div className='contact-card animate__animated animate__zoomIn anim-delay-1s-100ms'>
                    <div className='icons'>
                        <i className="fa-solid fa-phone"></i>
                        <i className="fa-brands fa-whatsapp"></i>
                    </div>
                    <div className='links'>
                        <a href="https://wa.me/+5212227492240" target="_blank">+52 222 74 92 24 0</a>
                        <a href="https://wa.me/+5212224173087" target="_blank">+52 222 41 73 08 7</a>
                    </div>
                </div>

                <div className='contact-card animate__animated animate__zoomIn anim-delay-1s-200ms'>
                    <div className='icons'>
                        <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className='links'>
                        <a href="mailto:kisystemssa@gmail.com" target="_blank">kisystemssa@gmail.com</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Soporte;